import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiButton } from '@elastic/eui';
import NavButtons from 'components/Quick_Installation/NavButtons';
import ForumBox from 'components/Quick_Installation/First_Steps/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "First Steps Installing your Camera",
  "path": "/Quick_Installation/First_Steps/",
  "dateChanged": "2017-11-22",
  "author": "Mike Polinowski",
  "excerpt": "What to do after you unpacked your camera.",
  "image": "./QI_SearchThumb_FirstStepsWithYourIPCamera.png",
  "social": "/images/Search/QI_SearchThumb_FirstStepsWithYourIPCamera.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_QI-First_Steps_white.webp",
  "chapter": "Installation"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const EuiSpacer = makeShortcode("EuiSpacer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='First Steps Installing your Camera' dateChanged='2017-11-22' author='Mike Polinowski' tag='INSTAR IP Camera' description='What to do after you unpacked your camera.' image='/images/Search/QI_SearchThumb_FirstStepsWithYourIPCamera.png' twitter='/images/Search/QI_SearchThumb_FirstStepsWithYourIPCamera.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Schnell_Installation/Erste_Schritte/' locationFR='/fr/Quick_Installation/First_Steps/' crumbLabel="First Steps" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "first-steps",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#first-steps",
        "aria-label": "first steps permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`First Steps`}</h2>
    <p>{`In the following, we will give you a short introduction to the basic functions of our IP-cameras and the first steps of installation. We will discuss common problems and answer frequently asked questions.`}</p>
    <p>{`Please feel free to follow our Installation Wizard to guide you through the installation process.`}</p>
    <Link to="https://install.instar.com" target="_blank" rel="noopener noreferrer" mdxType="Link"><EuiButton fill mdxType="EuiButton">Installation Wizard</EuiButton></Link>
    <h3 {...{
      "id": "advice-for-beginners",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#advice-for-beginners",
        "aria-label": "advice for beginners permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Advice for Beginners:`}</h3>
    <br />
    <ul>
      <li parentName="ul">{`The `}<OutboundLink to="https://install.instar.com/" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">{`Quick-Installation-Guide`}</OutboundLink>{` already guided you through the installation of your camera and showed you how to set up an user account. If you want to change the `}<strong parentName="li">{`login data`}</strong>{` afterwards, or if you want to add an additional visitor account, click `}<a parentName="li" {...{
          "href": "/en/Quick_Installation/Creating_User_Accounts"
        }}>{`HERE`}</a>{`.`}</li>
      <li parentName="ul">{`The `}<strong parentName="li">{`DEFAULT LOGIN`}</strong>{` for your camera is `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`admin`}</code>{` / `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`instar`}</code>{` (for old VGA cameras it is just `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`admin`}</code>{` without a password)`}</li>
      <li parentName="ul">{`If you cannot find your camera with our Instar Camera Tool for `}<OutboundLink to="http://www.instar.de/media/wysiwyg/Products/Software/Camera_Tool_Win/INSTAR_Camera_Tool_2.0.6.msi.zip" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">{`Windows`}</OutboundLink>{`, `}<OutboundLink to="https://itunes.apple.com/de/app/instar-camera-tool/id584435076?mt=12#" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">{`macOS`}</OutboundLink>{` and `}<OutboundLink to="http://www.instar.de/media/wysiwyg/Products/Software/Camera_Tool_Linux/INSTAR_Camera_Tool_LINUX.zip" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">{`LINUX`}</OutboundLink>{`, you can try using an `}<a parentName="li" {...{
          "href": "/en/Quick_Installation/Alternative_IP_Scanner/"
        }}>{`Alternative IP Scanner`}</a>{` or you `}<a parentName="li" {...{
          "href": "/en/Quick_Installation/Direct_LAN_Connection/"
        }}>{`directly connect`}</a>{` your camera with your computer.`}</li>
      <li parentName="ul">{`All camera settings can be adjusted in the `}<strong parentName="li">{`WebUI`}</strong>{`. We have a manual about all the functions of our `}<a parentName="li" {...{
          "href": "/en/Web_User_Interface/1080p_Series/"
        }}>{`1080p Series`}</a>{` and `}<a parentName="li" {...{
          "href": "/en/Web_User_Interface/720p_Series/"
        }}>{`720P Series`}</a>{` WebUI. If the WebUI is not using your preferred language, you will find the manual to change the language `}<a parentName="li" {...{
          "href": "/en/Quick_Installation/Language_Selection/"
        }}>{`HERE`}</a>{`.`}</li>
      <li parentName="ul">{`Check out our `}<strong parentName="li">{`Calculators`}</strong>{` for the `}<a parentName="li" {...{
          "href": "https://www.instar.de/tools/calc_lens"
        }}>{`Angle of View`}</a>{` of your camera, the `}<a parentName="li" {...{
          "href": "https://www.instar.com/support/help-tools"
        }}>{`Bandwidth Requirements`}</a>{` and `}<a parentName="li" {...{
          "href": "https://www.instar.com/support/help-tools"
        }}>{`SD Card Capacity`}</a>{`.`}</li>
      <li parentName="ul">{`We recommend to always use the latest update for your camera. Information about how to `}<strong parentName="li">{`Update the Camera-Software`}</strong>{` can be found `}<a parentName="li" {...{
          "href": "/en/Quick_Installation/WebUI_And_Firmware_Upgrade/"
        }}>{`HERE`}</a>{`.`}</li>
      <li parentName="ul">{`If you want to `}<strong parentName="li">{`remotely access`}</strong>{` your camera through the `}<strong parentName="li">{`Internet`}</strong>{`, you can check out our `}<a parentName="li" {...{
          "href": "/en/Internet_Access/The_DDNS_Service"
        }}>{`DDNS Service`}</a>{`.`}</li>
      <li parentName="ul">{`If your browser is not playing the `}<strong parentName="li">{`Live Video`}</strong>{`, you can find a manual for changing the video mode `}<a parentName="li" {...{
          "href": "/en/Quick_Installation/Live_Video"
        }}>{`HERE`}</a>{`.`}</li>
      <li parentName="ul">{`You can configure the `}<a parentName="li" {...{
          "href": "/en/Motion_Detection/Setup"
        }}>{`Motion Detection`}</a>{` and activate the `}<a parentName="li" {...{
          "href": "/en/Motion_Detection/Alarm_Notification"
        }}>{`Alarm Notification`}</a>{`.`}</li>
      <li parentName="ul">{`For further information on our cameras you can check out the respective online-manuals: `}<a parentName="li" {...{
          "href": "/en/Outdoor_Cameras/IN-5905_HD/Usermanual"
        }}>{`IN-5905 HD`}</a>{`, `}<a parentName="li" {...{
          "href": "/en/Outdoor_Cameras/IN-5907_HD/Usermanual"
        }}>{`IN-5907 HD`}</a>{`, `}<a parentName="li" {...{
          "href": "/en/Indoor_Cameras/IN-6001_HD/Usermanual"
        }}>{`IN-6001 HD`}</a>{`, `}<a parentName="li" {...{
          "href": "/en/Indoor_Cameras/IN-6012_HD/Usermanual"
        }}>{`IN-6012 HD`}</a>{`, `}<a parentName="li" {...{
          "href": "/en/Indoor_Cameras/IN-6014_HD/Usermanual"
        }}>{`IN-6014 HD`}</a>{`, `}<a parentName="li" {...{
          "href": "/en/Outdoor_Cameras/IN-7011_HD/Usermanual"
        }}>{`IN-7011 HD`}</a>{`, `}<a parentName="li" {...{
          "href": "/en/Indoor_Cameras/IN-8015_HD/Usermanual"
        }}>{`IN-8015 Full HD`}</a>{` und `}<a parentName="li" {...{
          "href": "/en/Outdoor_Cameras/IN-9008_HD/Usermanual"
        }}>{`IN-9008 Full HD`}</a>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "frequently-asked-questions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#frequently-asked-questions",
        "aria-label": "frequently asked questions permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Frequently Asked Questions`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#frequently-asked-questions"
        }}>{`Frequently Asked Questions`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#how-to-save-camera-recordings"
            }}>{`How to save Camera Recordings`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#camera-access-via-smartphone-app"
            }}>{`Camera-Access via Smartphone-App`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#external-cables-of-the-outdoor-cameras"
            }}>{`External Cables of the Outdoor-Cameras`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#heater-for-the-outdoor-cameras"
            }}>{`Heater for the Outdoor-Cameras`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#zoom-lense"
            }}>{`Zoom-Lense`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#battery-andor-sim-card"
            }}>{`Battery and/or SIM-Card`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#length-of-the-alarm-videos"
            }}>{`Length of the Alarm-Videos`}</a>
            {/* - [Time Lapse Recordings](#time-lapse-recordings) */}
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#synology-surveillance-station-and-qnap-nas"
            }}>{`Synology Surveillance Station and QNAP NAS`}</a></li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "how-to-save-camera-recordings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#how-to-save-camera-recordings",
        "aria-label": "how to save camera recordings permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How to save Camera Recordings`}</h3>
    <p>{`In total there are five different ways to save the `}<a parentName="p" {...{
        "href": "/en/Motion_Detection/Alarm_Recording/"
      }}>{`Video Recordings`}</a>{` of your camera:`}</p>
    <ol>
      <li parentName="ol">{`All of our HD IP Cameras have a SD-Card with a capacity of 4GB, 8GB or 16GB, depending on the camera model. `}<OutboundLink to="https://www.instar.com/support/help-tools" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">{`here`}</OutboundLink>{` you can calculate how long it takes until the storage of the SD-Card is full. The oldest recordings on the camera will be overwritten by default (Ring Storage). `}<a parentName="li" {...{
          "href": "/en/Motion_Detection/SD_Card_Access/"
        }}>{`Access your SD Card`}</a>{` with our smart phone app InstarVision for `}<a parentName="li" {...{
          "href": "/en/Software/Android/InstarVision/Recording/"
        }}>{`Android`}</a>{`, `}<a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/iPhone/Recording/"
        }}>{`iPhone`}</a>{`, `}<a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/iPad/Recording/"
        }}>{`iPad`}</a>{`, `}<a parentName="li" {...{
          "href": "/en/Software/Other_Platforms/InstarVision_Blackberry/"
        }}>{`Blackberry`}</a>{`, `}<a parentName="li" {...{
          "href": "/en/Software/Windows/InstarVision/Windows_Phone/Recording/"
        }}>{`Windows Phone`}</a>{` and `}<a parentName="li" {...{
          "href": "/en/Software/Windows/InstarVision/Metro/Recording/"
        }}>{`Windows 8/10`}</a>{`.`}</li>
      <li parentName="ol">{`We offer a `}<strong parentName="li">{`Cloud`}</strong>{`-solution to easily store recordings of your INSTAR Cameras. The `}<a parentName="li" {...{
          "href": "/en/Motion_Detection/INSTAR_Cloud/"
        }}>{`INSTAR Cloud`}</a>{` offers an easy-to-access interface and a good way to manage your recordings. If you are afraid that your camera and SD-Card could be destroyed, the INSTAR Cloud will give you the means to secure your recordings.`}</li>
      <li parentName="ol">{`You can use the `}<a parentName="li" {...{
          "href": "/en/Software/Windows/InstarVision/"
        }}>{`InstarVision 2.0 Surveillance Center`}</a>{`, a `}<strong parentName="li">{`Windows PC Software`}</strong>{` made by us. With this Software it is not only possible to show the live video of all your cameras at the same time, you can also start recordings and save them on your computer.`}</li>
      <li parentName="ol">{`It is also possible to use the `}<strong parentName="li">{`FTP`}</strong>{` (File Transfer Protocol) service to send your recordings to other devices, for example to an `}<a parentName="li" {...{
          "href": "/en/Motion_Detection/Router_as_a_FTP_Server/"
        }}>{`USB-Stick Connected To Your Router`}</a>{` or a hard-drive in your network.`}</li>
      <li parentName="ol">{`If you want to make permanent recordings you can also use `}<strong parentName="li">{`NAS Systems`}</strong>{`, e.g. `}<a parentName="li" {...{
          "href": "/en/Software/Other_Platforms/Synology/"
        }}>{`Synology`}</a>{` or `}<a parentName="li" {...{
          "href": "/en/Software/Other_Platforms/QNAP/"
        }}>{`QNAP`}</a>{`. These have their own surveillance software and support all our camera models.`}</li>
    </ol>
    <p><strong parentName="p">{`Addition`}</strong>{`: You can also activate the `}<a parentName="p" {...{
        "href": "/en/Motion_Detection/Alarm_Notification/"
      }}>{`Email-Service`}</a>{`, to receive snapshots by email after an alarm was triggered.`}</p>
    <h3 {...{
      "id": "camera-access-via-smartphone-app",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#camera-access-via-smartphone-app",
        "aria-label": "camera access via smartphone app permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Camera-Access via Smartphone-App`}</h3>
    <p>{`Use our free App InstarVision for `}<a parentName="p" {...{
        "href": "/en/Software/Android/InstarVision/"
      }}>{`Android`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Software/iOS/InstarVision/iPhone/"
      }}>{`iPhone`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Software/iOS/InstarVision/iPad/"
      }}>{`iPad`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Software/Other_Platforms/InstarVision_Blackberry/"
      }}>{`Blackberry`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Software/Windows/InstarVision/Windows_Phone/"
      }}>{`Windows Phone`}</a>{` and `}<a parentName="p" {...{
        "href": "/en/Software/Windows/InstarVision/Metro/"
      }}>{`Windows 8/10`}</a>{` to access the live video of your IP Camera. Moreover, you can access your SD Card and check alarm recordings and use functions such as activating-/ deactivating the motion detection or use the camera and smart phone as an intercommunication system.`}</p>
    <h3 {...{
      "id": "external-cables-of-the-outdoor-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#external-cables-of-the-outdoor-cameras",
        "aria-label": "external cables of the outdoor cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`External Cables of the Outdoor-Cameras`}</h3>
    <p>{`Except for our FullHD-models, all of our cameras have a so called `}<strong parentName="p">{`cable-tree`}</strong>{`, which consists of all cable-connectors for the camera. This cable tree is 80cm long and relatively thick. If you don´t want to drill a big hole through your wall, you can `}<strong parentName="p">{`remove those cables`}</strong>{` from your camera and then put the "thin end" through the wall. A video manual can be found here - `}<Link to="/Outdoor_Cameras/IN-5905_HD/Video/Cable/" mdxType="Link">{`IN-5905 HD`}</Link>{` and `}<Link to="/Outdoor_Cameras/IN-5907_HD/Video/Cable/" target="_blank" rel="noopener noreferrer" mdxType="Link">{`IN-5907 HD`}</Link>{`.`}</p>
    <p>{`We strongly advice against cutting the cables, as you will not only lose the guarantee for your camera, you also risk damaging of your camera. A solution would be to use a weatherproof cable-box to store the cable-tree.`}</p>
    <h3 {...{
      "id": "heater-for-the-outdoor-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#heater-for-the-outdoor-cameras",
        "aria-label": "heater for the outdoor cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Heater for the Outdoor-Cameras`}</h3>
    <p>{`Whether a heater is necessary for your camera depends on the location you want to install it in. Our cameras can be used down to -5 Degree Celsius. If the temperatures get even lower, the camera might not be damaged. However, it is possible that the camera will turn off and cannot be accessed until normal operating temperatures are reached again. The installation of a heater would guarantee the permanent operation of the camera. You can find heaters for the camera models `}<OutboundLink to="https://www.instar.de/accessories/heaters/heater-for-in-7011hd-1436.html" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">{`IN-7011 HD`}</OutboundLink>{`, `}<OutboundLink to="https://www.instar.de/accessories/heaters/heater-for-in-5907hd-1551.html" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">{`IN-5907 HD`}</OutboundLink>{`, `}<OutboundLink to="https://www.instar.de/accessories/heaters/heater-for-in-5905hd.html" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">{`IN-5905 HD`}</OutboundLink>{`, `}<OutboundLink to="https://www.instar.de/accessories/heaters/heater-for-in-4010v2.html" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">{`IN-4010 v2`}</OutboundLink>{`, `}<OutboundLink to="https://www.instar.de/accessories/heaters/heater-for-in-4132.html" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">{`IN-4010/11`}</OutboundLink>{`, `}<OutboundLink to="https://www.instar.de/accessories/heaters/heater-for-in-4133.html" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">{`IN-2908`}</OutboundLink>{` and `}<OutboundLink to="https://www.instar.de/accessories/heaters/heater-for-in-2904-in-2905-in-2969.html" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">{`IN-2904/5/7`}</OutboundLink>{` in our shop.`}</p>
    <h3 {...{
      "id": "zoom-lense",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#zoom-lense",
        "aria-label": "zoom lense permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Zoom-Lense`}</h3>
    <p>{`All of our HD- and FullHD-Cameras (except the IN-9020 FHD) do not have optical zoom lenses, but do offer digital zoom. Due to their resolution our HD cameras still offer a better quality with their 3-times digital zoom than our VGA-cameras with their "lossless" optical zoom.`}</p>
    <h3 {...{
      "id": "battery-andor-sim-card",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#battery-andor-sim-card",
        "aria-label": "battery andor sim card permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Battery and/or SIM-Card`}</h3>
    <p>{`Our cameras do not have an internal `}<strong parentName="p">{`Battery`}</strong>{`. Therefore, they must constantly be connected via power adapter.
Our cameras do not have a `}<strong parentName="p">{`SIM-Card-Slot`}</strong>{`. If you want to use the camera at a location without internet access, you can use a mobile LTE-Router. The camera can then be connected to the router and will have access to the internet.`}</p>
    <h3 {...{
      "id": "length-of-the-alarm-videos",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#length-of-the-alarm-videos",
        "aria-label": "length of the alarm videos permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Length of the Alarm-Videos`}</h3>
    <p>{`The alarm video starts 3 seconds before the alarm event and ends 15 seconds after - in total the alarm video is 17-18s. If the movement continues after the recording, a new alarm event will be triggered and a new 15s video will be recorded.`}</p>
    <h3 {...{
      "id": "synology-surveillance-station-and-qnap-nas",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#synology-surveillance-station-and-qnap-nas",
        "aria-label": "synology surveillance station and qnap nas permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Synology Surveillance Station and QNAP NAS`}</h3>
    <p>{`Synology and QNAP have their own surveillance software and can use the live video of our cameras. All of our HD- and FullHD Cameras are compatible. For the implementation of our cameras into their surveillance station you can follow our `}<a parentName="p" {...{
        "href": "/en/Software/Other_Platforms/Synology/"
      }}>{`Manual`}</a>{`.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      